<template>
    <div class="chat-wrapper">
        <div class="chat-bot-btn" @mouseenter="animate_btn" id="chat-bot-btn" @click="open_chat_screen">
            <div class="icon">
                <img src="https://image.flaticon.com/icons/png/512/1041/1041916.png">
            </div>
            <div class="text">
                <p>תמיכה</p>
            </div>
        </div>
    </div>
    <div class="chat-screen-wrapper" id="chat-screen-wrapper">
        <div class="chat-screen slide-out-left" id="chat-screen">
            <div class="close" @click="close_chat">
                <i class="el-icon-circle-close" style="color:var(--bg-blue); font-size: 22px;"/>
            </div>
            <div class="title">
                <p>אנחנו כאן לשירותכם</p>
            </div>
            <div class="chat">
                <Chat/>
            </div>
            <div class="footer">
                <el-button type="primary" icon="el-icon-phone" circle class="footer-btn"></el-button>
                <el-button type="primary" icon="el-icon-message" circle class="footer-btn"></el-button>
                <el-button type="primary" icon="el-icon-tickets" circle class="footer-btn"></el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Chat from './Chat.vue';

export default {
components:{Chat},
setup(){

    const animate_btn = () => {
        const ele = document.getElementById('chat-bot-btn');
        if(ele){
            ele.classList.add('jello-horizontal');
            setTimeout(() => {
                ele.classList.remove('jello-horizontal')
            }, 900);
        }
    }

    const open_chat_screen = () => {
        const wrapper = document.getElementById('chat-screen-wrapper');
        if(wrapper){
            const screen = document.getElementById('chat-screen');
            wrapper.style.display = 'flex';
            screen.classList.replace('slide-out-left','slide-in-left');
        }
    }

    const close_chat = () => {
        const wrapper = document.getElementById('chat-screen-wrapper');
        if(wrapper){
            const screen = document.getElementById('chat-screen');
            screen.classList.replace('slide-in-left','slide-out-left');
            setTimeout(() => {
                wrapper.style.display = 'none';
            }, 900);
        } 
    }

    return{
        animate_btn, open_chat_screen, close_chat
    }
}
}
</script>

<style scoped>
.chat-wrapper{
    position: absolute;
    top: 50%;
    left: 0;
    width: 50px;
    height: 130px;
    overflow: hidden;
    z-index: 1010; 
  
}
.chat-bot-btn{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1010;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--bg-bg);
    color: var(--bg-white);
    display: grid;
    grid-template-areas: 
    "icon"
    "text";
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;
    cursor: pointer;
}

.icon{
    width: 100%;
    height: 100%;
    grid-area: icon;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon img{
    max-width: 75%;
    max-height: 75%;
}
.text{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: text;
    height: 100%;
    width: 100%;
    writing-mode: vertical-rl;
    font-size: 22px;
}
.text p{
    font-weight: 200;
    letter-spacing: 1.5px;
}

.chat-screen-wrapper{
    display: none;
    position: absolute;
    top: calc(50% - 3px);
    left: 0;
    width: 375px;
    height: 435px;
    z-index: 1010;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
}
.chat-screen{
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: grid;
    grid-template-areas: 
    "close  title"
    "chat   chat"
    "chat   chat"
    "footer footer";
    grid-template-rows: 15% 35% 35% 15%;
    grid-template-columns: 15% 85%;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.close{
    grid-area: close;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.title{
    grid-area: title;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.chat{
    grid-area: chat;
    width: 100%;
    height: 100%;
}
.footer{
    grid-area: footer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
}
.footer-btn{
    margin-right: 5px;
}

.jello-horizontal {
	-webkit-animation: jello-horizontal 0.7s both;
	        animation: jello-horizontal 0.7s both;
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-out-left {
	-webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

::v-deep(.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered){
    margin-left: 0;
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
    .chat-wrapper{
      display: none;
    }
    .chat-screen-wrapper{
      top: calc(15vh);
      height: 75vh;
      width: 98%;
    }
}
</style>