<template>
    <div class="chat">
        <div class="screen" id="screen">
            <template v-for="(msg,i) in msgs" :key="i">
                <div class="chat-msg-system" v-if="msg.role == 'support'" :id="msg.id">
                    <img src="@/assets/LOGO_100.png" alt="">
                    <p>{{msg.msg}}</p>
                </div>
                <div class="chat-msg-client" v-else :id="msg.id">
                    <p>{{msg.msg}}</p>
                </div>
            </template>
        </div>
        <div class="input">
            <el-input placeholder="הקלד כאן..." v-model="input_string" @keypress="send_msg"/>
        </div>
        <div class="btns">
            <el-button type="success" icon="el-icon-finished" @click="send_msg({key: 'Enter'})"></el-button>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core';
import store from '../../store';
import router from '../../router';
export default {
setup(){
    const user = ref(computed(() => {
        return store.getters.user
    }))

    const input_string = ref('');
    const msgs = ref([
        {
            msg: 'שלום, ספר לנו כיצד אפשר לעזור?',
            role: 'support',
            id: Math.random()
        }
    ]);

    watch(msgs.value, () => {
        setTimeout(() => {
            const msg_id = document.getElementById(msgs.value[msgs.value.length - 1].id)
            msg_id.scrollIntoView({behavior:'smooth'});
        }, 250);
    })

    const send_msg = (e) =>{
        if(e.key == 'Enter'){
            if(user.value){
                if(input_string.value){
                    let id = Math.random()
                    msgs.value.push({
                        role: 'client',
                        msg: input_string.value,
                        id: id
                    })
                    input_string.value = '';
                }
            }else{
                let id = Math.random()
                msgs.value.push({
                    msg: 'אנא התחבר תחילה למערכת',
                    role: 'support',
                    id: id
                })
                setTimeout(() => {
                    let id = Math.random()
                    msgs.value.push({
                        msg: 'אם אין לך משתמש עדיין, ניתן ליצור קשר באמצעות טופס יצירת קשר',
                        role: 'support',
                        id: id
                    })
                    router.push('start')
                }, 500);
            }
        }       
    }

    return{
        input_string, msgs, send_msg
    }
}
}
</script>

<style scoped>
.chat{
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "screen screen"
    "input  btns";
    grid-template-columns: 75% 25%;
    grid-template-rows: 70% 30%;
    overflow: hidden;
}
.screen{
    grid-area: screen;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: grid;
    gap: 5px;
    grid-auto-columns: 100%;
    grid-auto-rows: max-content;
    overflow-y: auto;
    padding: 10px;
}
.input{
    grid-area: input;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.btns{
    grid-area: btns;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.chat-msg-system{
    background-color: var(--bg-blue);
    color: whitesmoke;
    font-size: 18px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 5px;
}
.chat-msg-system img{
    background-color: white;
    padding: 2px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    margin-left: 5px;
}
.chat-msg-client{
    background-color: var(--bg-lightblue);
    color: var(--bg-black);
    font-size: 18px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 5px;
}
@media only screen and (max-width: 768px) {
    
}
</style>