<template>

    <div class="mobile-menu">
        <div class="top-menu-icon">
            <img src="https://image.flaticon.com/icons/png/512/747/747496.png" alt="" class="menu-btn" @click="toggle_menu">
        </div>
    </div>

    <div class="side-bar" id="side-menu">
        <div class="top-icon">
            <div class="title" @click="navigate_to('')">
                CYBERPARK
                <p class="sub-title">COMM. LTD</p>
            </div>
            <div class="top-menu-icon">
                <img src="https://image.flaticon.com/icons/png/512/747/747496.png" alt="" class="menu-btn" @click="toggle_menu">
            </div>
        </div>
        <div class="menu-content">
            <div class="menu-header-cp">
                <div class="cp-icon">
                    <img src="@/assets/LOGO_100.png" alt="">
                </div>
                <div class="cp-header">סייבר פארק</div>
                <div class="cp-content">"העולם הופך להיות פארק טכנולוגי ענק ואנחנו כאן לדאוג שיהיה לכם כרטיס כניסה"</div>
                <div class="cp-sub">איבגי, מנכ"ל</div>
            </div>
            <div class="menu-link" @click="navigate_to('tools')">
                <p>כלים לעסקים</p>
            </div>
            <div class="menu-link" @click="navigate_to('about')">
                <p>קצת עלינו</p>
            </div>
            <div class="menu-link" @click="navigate_to('method')">
                <p>השיטה</p>
            </div>
            <div class="menu-link"  @click="navigate_to('projects')">
                <p>פרויקטים</p>
            </div>
            <div class="menu-link"  @click="navigate_to('start')">
                <p>צור קשר</p>
            </div>
        </div>
        <div class="fast-links">
            <div class="fast-link">
                <el-tooltip class="item" effect="light" content="ערוץ היוטיוב שלנו" placement="left-start">
                    <img src="https://image.flaticon.com/icons/png/512/3781/3781721.png" alt="">
                </el-tooltip>
            </div>
            <div class="fast-link">
                <el-tooltip class="item" effect="light" content="עמוד הפייסבוק שלנו" placement="left-start">
                    <img src="https://image.flaticon.com/icons/png/512/1384/1384069.png" alt="">
                </el-tooltip>
            </div>
            <div class="fast-link">
                <el-tooltip class="item" effect="light" content="צור קשר בווטסאפ" placement="left-start">
                    <img src="https://image.flaticon.com/icons/png/512/1384/1384079.png" alt="">
                </el-tooltip>
            </div>
        </div>
        <div class="footer">
            <div class="footer-btn">
                <button type="button" @click="navigate_to('chat')"> תמיכה </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import router from '../../router';
import { computed, onMounted, watch } from '@vue/runtime-core';
export default {
setup(){
    const toggle_menu = () => {
        const menu = document.getElementById('side-menu')
        if(window.innerWidth < 1004){
            if(menu.style.display =='' || menu.style.display =='none'){
                menu.style.display = 'grid';
                setTimeout(() => {
                    menu.classList.toggle('open');
                    
                }, 150);
            }else{
                if(menu.style.display == 'grid'){
                    menu.classList.toggle('open');
                    setTimeout(() => {
                        menu.style.display = 'none';
                    }, 300);
                }
            }
        }else{
            menu.classList.toggle('open');
        }
    }

    const navigate_to = (path) => {
        if(path != 'chat'){
            router.push('/'+ path);
            toggle_menu();
        }
        if(path == 'chat'){
            open_chat();
        }
    }

    const change_bg = () => {
        const ele = document.getElementById('side-menu');
        if(ele){
            ele.style.background = 'var(--bg-bg)';
        }
    }

    const change_back = () =>{
        const ele = document.getElementById('side-menu');
        if(ele){
            ele.style.background = 'linear-gradient(#00545dc4, #000729c2)';
        }
    }

    const open_chat = () =>{
        toggle_menu();
        setTimeout(() => {
            const wrapper = document.getElementById('chat-screen-wrapper');
            if(wrapper){
                const screen = document.getElementById('chat-screen');
                wrapper.style.display = 'flex';
                screen.classList.replace('slide-out-left','slide-in-left');
            }
        }, 150);
    }

    const current_path = ref(computed(() => {
        return router.currentRoute
    }))

    watch(current_path.value, () => {
        let path = current_path.value.value.fullPath;
        if(path != '/'){
            change_bg();
        }else{
            if(window.innerWidth > 1004){
                change_back();
            } 
        }
    })

    return{
        toggle_menu, navigate_to, open_chat
    }
}
}
</script>

<style scoped>
.side-bar{
    position: absolute;
    width: 375px;
    height: 100%;
    z-index: 1000;
    top: 0;
    right: -310px;
    background: linear-gradient(#00545dc4, #000729c2);
    overflow: hidden;
    transition: ease-in-out 0.3s;
    display: grid;
    grid-template-areas:
    "top-icon       top-icon"
    "menu-content   fast-links"
    "footer         footer";
    grid-template-rows: 80px calc(100% - 80px - 120px) 120px;
    grid-template-columns:calc(100% - 60px)  60px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
.mobile-menu{
    display: none;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 7px;
    top: 15px;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.open{
    right: 0;
}
.top-icon{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 3px;
    overflow: hidden;
    align-items: center;
    grid-area: top-icon;
}
.top-icon .title{
    width: calc(100% - 60px);
    text-align: center;
    color: #fbfcfd;
    letter-spacing: 1px;
    font-size: 36px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.top-icon .title .sub-title{
    font-size: 14px;
    color: rgb(221, 221, 221);
}
.top-menu-icon{
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-btn{
    width: 50px;
    font-size: 50px;
    color: #fbfcfd;
    cursor: pointer;
}
.fast-links{
    width: 100%;
    height: 100%;
    grid-area: fast-links;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.fast-link{
    width: 100%;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fast-link img{
    max-width: 60%;
    max-height: auto;
    cursor: pointer;
}

.menu-content{
    width: 100%;
    height: 100%;
    grid-area: menu-content;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 120px 80px 80px 80px 80px 80px;
    padding: 10px;
    gap: 5px;
    overflow: hidden;
}
.menu-header-cp{
    background: white;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1));
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: auto;
    display: grid;
    grid-template-areas: 
    "cp-icon    cp-header"
    "cp-icon    cp-content"
    "cp-icon    cp-sub";
    grid-template-columns: 25% 75%;
    grid-template-rows: 30% 50% 20%;
    padding: 5px;
    overflow: hidden;
}
.cp-header{
    grid-area: cp-header;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bg-darkblue);
    font-size: 20px;
    font-weight: 500;
}
.cp-icon{
    grid-area: cp-icon;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cp-icon img{
    max-width: 100%;
    max-height: 100%;
    background-color: rgba(255, 255, 255, 0.726);
    border-radius: 50%;
}
.cp-content{
    grid-area: cp-content;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bg-darkblue);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.cp-sub{
    grid-area: cp-sub;
    width: 100%;
    height: 100%;
    color: var(--bg-darkblue);
    font-size: 13px;
    font-weight: 400;
    display: flex;
    direction: ltr;
    align-items: center;
}

.footer{
    width: 100%;
    height: 100%;
    grid-area: footer;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.footer-btn{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
button{
    color: #fbfcfd;
    padding: 10px 25px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    width: 100%;
    font-size: 2vh;
}
.menu-link{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fbfcfd;
    font-size: 27px;
    transition: 0.3s ease-in-out;
}
.menu-link:hover{
    background-color: rgba(245, 245, 245, 0.034);
    cursor: pointer;
}

@media only screen and (max-width: 1004px) {
    .side-bar{
        background: var(--bg-bg);
        display: none;
        width: 310px;
        grid-template-areas:
        "top-icon       top-icon"
        "menu-content   menu-content"
        "footer         footer";
    }
    .mobile-menu{
        display: flex;
    }
    .fast-link{
        display: none;
    }
    .menu-content{
        grid-template-rows: 120px calc(calc(100% - 120px) / 5) calc(calc(100% - 120px) / 5) calc(calc(100% - 120px) / 5) calc(calc(100% - 120px) / 5) calc(calc(100% - 120px) / 5);
    }
    .footer{
        display: flex;
    }
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>