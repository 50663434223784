import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBXLcvm2GccRx8lrrMF2JQ97RwwsFJt9gE",
    authDomain: "cyberparkcomm.firebaseapp.com",
    projectId: "cyberparkcomm",
    storageBucket: "cyberparkcomm.appspot.com",
    messagingSenderId: "403809108246",
    appId: "1:403809108246:web:ebbe6a7886d21c2102d037",
    measurementId: "G-D1MZKT7F36"
};


// init firebase
var myApp = firebase.initializeApp(firebaseConfig);

// init services 

const projectFirestore = myApp.firestore();
const projectAuth = myApp.auth();
const projectStorage = myApp.storage();
const projectFunctions=myApp.functions();
const FireStore = firebase.firestore;
const projectDataBase = myApp.database();
const projectDb = firebase.database;


// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore,projectStorage, projectAuth, timestamp, FireStore, projectFunctions, projectDataBase, projectDb};