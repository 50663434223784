import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import {projectAuth} from './firebase/config';
import locale from 'element-plus/lib/locale/lang/he';
import colorPicker from "vue3-colorpicker";
import "vue3-colorpicker/bundle.css";

projectAuth.onAuthStateChanged( user => {
    store.dispatch("set_user", user);
    if(!user){
        router.replace('/');
    }
});

createApp(App)
.use(store)
.use(ElementPlus, {locale})
.use(colorPicker)
.use(router)
.mount('#app')
