<template>
    <div class="main-storage" id="main-storage">
        <div class="close-btn" @click="exit_storage">
            <img src="https://image.flaticon.com/icons/png/512/747/747539.png" alt="">
        </div>
        <div class="title">
            <p>האחסון שלי</p>
        </div>
        <div class="apps">
            <div class="filters">
                <div class="search-bar">
                    <el-input placeholder="חיפוש" v-model="search_string"></el-input>
                </div>
                <div class="filter-btns">
                    <div class="filter-btn" @click="filter = 'images'">
                        <p v-if="filter == 'images'" class="chosen-filter">תמונות</p>
                        <p v-else> תמונות</p>                     
                    </div>
                    <div class="filter-btn" @click="filter = 'docs'">
                        <p v-if="filter == 'docs'" class="chosen-filter">מסמכים</p>
                        <p v-else> מסמכים</p>  
                    </div>                  
                    <div class="filter-btn" @click="filter = 'all'">
                        <p v-if="filter == 'all'" class="chosen-filter">הכל</p>
                        <p v-else> הכל</p>
                    </div>
                </div>
            </div>
            <div class="analitics">
                <Vue3ChartJs :id="space_chart.id" :type="space_chart.type" :data="space_chart.data" ref="chartRef"/>
            </div>
        </div>
        <div class="storage">
            <template v-for="item in filtered_user_sorage">
                <div class="storage-item">
                    <div class="item-icon">
                        <img v-if="item.type == 'txt'" src="https://image.flaticon.com/icons/png/512/337/337956.png" alt="">
                        <img v-if="item.type == 'xlsx'" src="https://image.flaticon.com/icons/png/512/337/337958.png" alt="">
                        <img v-if="item.type == 'pdf'" src="https://image.flaticon.com/icons/png/512/337/337946.png" alt="">
                        <img v-if="item.type == 'jpg' || item.type == 'png'" :src="item.path" alt="">
                    </div>
                    <p class="item-name">{{item.name}}</p>
                    <p class="item-size">{{`${item.size.toFixed(3)} kb`}}</p>
                </div>
            </template>
        </div>
        <div class="footer">
            <el-upload
            class="upload-file-box" drag :http-request ="upload_file" :show-file-list="false" action="" accept="image/png, image/jpeg, 
            image/jpg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, xlsx">
            <i class="el-icon-upload" style="margin:0;"></i>
            <div class="el-upload__text">
                גרור קובץ להעלאה <em>או לחץ כאן</em>
                </div>
            </el-upload>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
import store from '../../../store'
import { projectAuth, projectStorage } from '../../../firebase/config'
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ElNotification } from "element-plus";

export default {
components:{Vue3ChartJs},
setup(){
    const chartRef = ref(null)

    const user = ref(computed(() => {
        return store.getters.user
    }));
    
    const user_sorage = ref(null);
    const user_space = ref(0);
    const search_string = ref('');
    const filter = ref('all')

    const filtered_user_sorage = ref(computed(() =>{
        if(user_sorage.value){
            let return_data = user_sorage.value.sort((itmA, itmB) => {
                if(itmA.type > itmB.type){return -1}
                if(itmA.type < itmB.type){return 1}
                return 0
            })
            switch (filter.value) {
                case 'all':
                    return_data =  user_sorage.value
                    break
                case 'images':
                    return_data =  user_sorage.value.filter(item => {
                        return item.type.includes('png') || item.type.includes('jpg') || item.type.includes('jpeg')
                    });
                    break
                case 'docs':
                    return_data =  user_sorage.value.filter(item => {
                        return item.type.includes('pdf') || item.type.includes('xlsx')
                    });
                    break
                default:
                    return_data =  user_sorage.value
                    break
            } 
            if(search_string.value && search_string.value.length > 1){
                return_data = return_data.filter(item => {
                    return item.name.includes(search_string.value)
                })
            }
            return return_data  
        }   
    }))

    const show_storage = ref(computed(() =>{
        return store.getters.user_storage
    }))

    watch(show_storage, () => {
        if(show_storage.value){
            open_window();
        }
        else{
            close_window();
        }
    })

    const open_window = () => {
        const el = document.getElementById('main-storage')
        if(el){
            el.style.display = 'grid';
            if(el.classList.contains('slide-out-right')){
                el.classList.toggle('slide-out-right');
            }
            el.classList.toggle('slide-in-right');
            if(!user_sorage.value){
                get_user_storage();
            }
        }
    }

    const close_window = () => {
        const el = document.getElementById('main-storage')
        if(el){
            el.classList.toggle('slide-in-right');
            el.classList.toggle('slide-out-right');
            setTimeout(() => {
                el.style.display = 'none';
            }, 500);
        }
    }

    const exit_storage = () =>{
        store.dispatch('toggle_user_storage')
    }

    const get_user_storage = async () =>{
        if(user.value){
            await projectStorage.ref(`Users/${projectAuth.currentUser.uid}/init.txt`).getDownloadURL()
            .catch(async err => {
                if(err.code == 'storage/object-not-found'){
                    await create_user_db()
                }
                else{
                    console.log(err.code);
                }
            }).then((data) => {
                if(data){
                    console.log('Storage is init!');
                    get_user_files();
                    user_sorage.value = [];
                }
            })
        }
    }

    const create_user_db = async () => {
        console.log('Creating user db.');
        await projectStorage.ref(`Users/${projectAuth.currentUser.uid}/init.txt`).put('init');
    }

    const get_user_files = async () =>{
        user_space.value = 0;
        user_sorage.value = [];
        const items_list = await projectStorage.ref(`Users/${projectAuth.currentUser.uid}/`).list()
        items_list.items.forEach(item => {
            item.getMetadata().then(item_data=>{
                item.getDownloadURL().then(url => {
                    user_sorage.value.push({
                        name: item_data.name.split('.')[0],
                        type: item_data.name.split('.')[item_data.name.split('.').length - 1],
                        path: url,
                        size: item_data.size * 0.001
                    });
                    user_space.value += item_data.size * 0.001;
                    space_chart.data.datasets[0].data = [user_space.value, 10000];
                    chartRef.value.update();
                })
            })
        })
        console.log(user_sorage.value);
        
    }

    const space_chart = {
            id: 'my-chart',
            type: 'bar',
            data: {
                labels:['בשימוש', 'פנוי'],
                datasets: [{
                    label: 'שטח אחסון',
                    data: [user_space.value, 10000],
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                    ],
                    hoverOffset: 4
                }]
            }
    }

    const upload_file = (e) =>{
        var file = e.file
        const file_types = ['image/png', 'image/jpg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'image/jpeg', 'xlsx']
        if(file_types.includes(file.type)){
            if(file.size <= 3145728){
                put_in_storage(file);
            }else{
                slide_pop_error('משקל הקובץ חורג ממגבלות ההעלאה, עד 3 מגה ביט.')
            }
        }else{
            slide_pop_error('סוג הקובץ איננו נתמך בשלב זה.')
        }
    }

    const put_in_storage = (file) =>{
        var storage = projectStorage.ref(`Users/${projectAuth.currentUser.uid}/${file.name}`);
        var upload = storage.put(file);
        upload.on("state_changed",
            function progress(snapshot) {
                var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //document.getElementById("progress").value = percentage;
            },
            function error() {
                slide_pop_error('שגיאה בעת העלאת הקובץ, פנה לתמיכה טכנית.');
            },

            function complete() {
                slide_pop_successs('הקובץ הועלה בהצלחה!')
                get_user_files();
            }
        );
    }

    const slide_pop_error = (text) => {
        ElNotification({
            title: "שגיאה!",
            type: "error",
            message: text,
            showClose: false,
            duration: 2500
        });
    }
    
    const slide_pop_successs = (text) => {
        ElNotification({
            title: "הצלחה",
            type: "success",
            message: text,
            showClose: false,
            duration: 2000,
            zIndex: '10000000'
        });
    }

    return{
        exit_storage, space_chart, user_space, chartRef,
        upload_file, filtered_user_sorage, search_string, filter
    }
}
}
</script>

<style scoped>
::v-deep(.el-upload-dragger){
    position: relative;
   width: 90%; 
   height: 100%;
   margin: auto;
   padding: 0;
   border: none;
   display: flex;
   align-items: center;
   justify-content: center;
   background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.7));
}
::v-deep(.el-upload){
    display: unset;
}

.main-storage{
    position: absolute;
    width: 33%;
    min-height: 100%;
    z-index: 10000;
    right: 65px;
    top: 0;
    background: var(--bg-bg);
    display: none;
    grid-template-areas: 
    "title"
    "apps"
    "storage"
    "footer";
    grid-template-columns: 100%;
    grid-template-rows: 10% 15% 65% 10%;
}
.title{
    grid-area: title;
    width: 100%;
    height: 100%;
}
.title p{
    text-align: center;
    font-size: 5vh;
    color: whitesmoke;
}
.apps{
    width: 100%;
    height: 100%;
    grid-area: apps;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.apps .analitics{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.apps .filters{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.apps .filters .search-bar{
    width: 100%;
    height: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chosen-filter{
    width: 100%;
    text-align: center;
    color: var(--bg-blue);
    font-weight: 500;
}
.apps .filters .filter-btns{
    height: 50%;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.apps .filters .filter-btns .filter-btn{
    width: 32%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.5));
    border-radius: 5px;
    cursor: pointer;
}
.storage{
    width: 100%;
    height: 100%;
    grid-area: storage;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-auto-rows: 20%;
    overflow-y: auto;
    padding: 15px 10px 15px 10px;
}
.close-btn{
    top: 15px;
    left: 15px;
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
}
.close-btn img{
    max-width: 100%;
    max-height: 100%;
}
.storage-item{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    overflow: hidden;
}
.storage-item .item-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
}
.item-icon img{
    max-width: 95%;
    max-height: 95%;
    border-radius: 5px;
}
.item-name{
    height: 30%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: whitesmoke;
}
.item-size{
    position: absolute;
    height: auto;
    width: fit-content;
    padding: 3px;
    text-align: center;
    font-size: 10px;
    color: whitesmoke;
    top: 5px;
    left: 5px;
    background: var(--bg-blue);
    border-radius: 2px;
}
.footer{
    grid-area: footer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 5px;
}
.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.upload-file-box{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

</style>