<template>
    <div class="home" id="home">
        <div class="middle" id="middle">
            <div class="title">תחשבו בגדול</div>
            <div class="sub-title">אנחנו נדאג לכל השאר</div>
            <button type="button" @click="nav_btn_clicked('tools')">כלים עסקיים</button>
            <button type="button" @click="nav_btn_clicked('start')">צור קשר</button>
        </div>
    </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import router from '../router';

export default {
components:{},
setup(){
    const nav_btn_clicked = (path) => {
        const middle_el = document.getElementById('middle');
        const home_el = document.getElementById('home');
        if(middle_el){
            middle_el.classList.toggle('fade-out');
            setTimeout(() => {
                home_el.classList.toggle('zoom-in')
                setTimeout(() => {
                    router.push(path)
                }, 1500);
            }, 400);
        }
    }

    onMounted(() => {
        const home = document.getElementById('home');
        if(home){
            home.classList.toggle('fade-in')
        }
    })

    return{
        nav_btn_clicked
    }
}
}
</script>

<style scoped>
.home{
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/home-back.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-areas: 
    "right-top top-center left-top"
    "right-center middle left-center"
    "right-bottom center-bottom left-bottom";
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 20% 60% 20%;
    transition: 1.5s ease-in-out;
}
.left-top{
    width: 100%;
    height: 100%;
    grid-area: left-top;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.left-center{
    width: 100%;
    height: 100%;
    grid-area: left-center;
}
.left-bottom{
    width: 100%;
    height: 100%;
    grid-area: left-bottom;
}
/******************************* top center **************************/
.top-center{
    width: 100%;
    height: 100%;
    grid-area: top-center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
}
.top-center .title{
    font-size: 8vh;
    font-weight: 400;
    text-shadow: 1px 1px 5px #0000008a;
}
.top-center .sub-title{
    font-size: 4vh;
    font-weight: 400;
    text-shadow: 1px 1px 5px #0000008a;
}
/***********************************************************************/
/********************************** midlle *****************************/
.middle{
    width: 100%;
    height: 100%;
    grid-area: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.middle .title{
    font-size: 8vh;
    font-weight: 400;
    text-shadow: 1px 1px 5px #0000008a;
}
.middle .sub-title{
    font-size: 4vh;
    font-weight: 400;
    text-shadow: 1px 1px 5px #0000008a;
}
/***********************************************************************/
.center-bottom{
    width: 100%;
    height: 100%;
    grid-area: center-bottom;
}
.right-top{
    width: 100%;
    height: 100%;
    grid-area: right-top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
}
.right-center{
    width: 100%;
    height: 100%;
    grid-area: right-center;
}
.right-bottom{
    width: 100%;
    height: 100%;
    grid-area: right-bottom;
}


button{
    color: #fbfcfd;
    padding: 10px 25px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    min-width: 160px;
    margin-top: 15px;
    font-size: 2vh;
}
button:hover{
    background: linear-gradient(#00545db0, #000729ab);
}

.fade-out{
    opacity: 0;
}
.zoom-in{
    transform: scale(100);
}

@media only screen and (max-width: 1004px) {
    .home{
        grid-template-areas: 
            "top-center"
            "middle"
            "center-bottom";
        grid-template-columns: 100%;
    }
    .right-top{
        display: none;
    }
    .right-center{
        display: none;
    }
    .right-bottom{
        display: none;
    }
    .left-top{
        display: none;
    }
    .left-center{
        display: none;
    }
    .left-bottom{
        display: none;
    }
}
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>