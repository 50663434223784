<template>
    <div class="user-icon slide-in-top" v-if="state == 'closed'" @click="show_widget">
        <el-tooltip class="item" effect="light" content="פתח את חלון המשתמש" placement="right-start">
            <img src="https://image.flaticon.com/icons/png/512/1177/1177568.png" alt="">
        </el-tooltip>
    </div>
    <div class="user-widget slide-in-top" id="user-widget">
        <el-tooltip class="item" effect="light" content="מזער את חלון המשתמש" placement="right-start">
            <div class="minimize" @click="minimize_widget">
                <img src="https://image.flaticon.com/icons/png/512/1665/1665734.png" alt="">
            </div>
        </el-tooltip>
        <div class="no-user" v-if="!user">
            <div class="title">
                <p>הכנס למערכת</p>
            </div>
            <div class="input">
                <input type="email" v-model="input_data.email" class="input_glass" placeholder="כתובת אימייל" >
            </div>
            <div class="input">
                <input type="password" v-model="input_data.password" class="input_glass" placeholder="סיסמה">
            </div>
            <div class="login-btn">
                <button type="button" @click="login">התחבר</button>
            </div>
            <div class="forgot-pass">
                <p @click="open_register">הירשם</p>
                <p @click="forgot_password">שכחתי סיסמה</p>
            </div>
        </div>

        <div class="user-in" v-if="user">
            <div class="user-btn" @click="log_out">
                <img class="user-btn-image" src="https://image.flaticon.com/icons/png/512/1665/1665737.png" alt="">
                <p class="user-btn-txt logout-red">התנתק</p>
            </div>
            <div class="user-btn" @click="open_storage">
                <img class="user-btn-image" src="https://image.flaticon.com/icons/png/512/1665/1665752.png" alt="">
                <p class="user-btn-txt">אחסון</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core'
import store from '../../store'
import { projectAuth } from '../../firebase/config'
import { ElNotification } from "element-plus";
import router from '../../router'

export default {
setup(){
    const user = ref(computed(() => {
        return store.getters.user
    }));
    const current_path = ref(computed(() => {
        return router.currentRoute
    }))

    const user_widget = ref(computed(() =>{ 
      return store.getters.user_widget
    }))

    const open_storage = () =>{
        store.dispatch('toggle_user_storage')
    }

    watch((user_widget) , () =>{
        if(user_widget.value){
            show_widget();
        }else{
            minimize_widget();
        }
    })
    const state = ref('open')

    const input_data = ref({email: '', password:''});

    const slide_pop_error = (text) => {
        ElNotification({
            title: "שגיאה!",
            type: "error",
            message: text,
            showClose: false,
            duration: 1500
        });
    }
    
    const slide_pop_info = (text) => {
        ElNotification({
            title: "הודעה",
            type: "info",
            message: text,
            showClose: false,
            duration: 2500
        });
    }
    
    const slide_pop_successs = (text) => {
        ElNotification({
            title: "הצלחה",
            type: "success",
            message: text,
            showClose: false,
            duration: 1000
        });
    }

    const login = () =>{
        projectAuth.signInWithEmailAndPassword(input_data.value.email, input_data.value.password).then((user) => {
            if(user){
                slide_pop_successs('התחברת בהצלחה!')
            }
        }) .catch((error) => {
            var errorCode = error.code;
            switch (errorCode) {
                case 'auth/wrong-password':
                    slide_pop_error('פרטי כניסה שגויים!')
                    break;
                case 'auth/user-not-found':
                    slide_pop_error('פרטי כניסה שגויים!')
                    break;
                case 'auth/invalid-email':
                    slide_pop_error('כתובת האימייל איננה תקינה!')
                    break;
                default:
                    break;
            }
            console.log(errorCode);
        });
    }

    const minimize_widget = () => {
        const widg_ele = document.getElementById('user-widget');
        if(widg_ele){
            widg_ele.classList.toggle('scale-out-center');
            state.value = 'closed'
        }
    }

    const show_widget = () => {
        const widg_ele = document.getElementById('user-widget');
        if(widg_ele){
            widg_ele.style.display = 'flex';
            widg_ele.classList.remove('scale-out-center');
            state.value = 'open'
        }
    }

    const log_out = () => {
        projectAuth.signOut()
        .then(() => {
            slide_pop_successs('התנתקת בהצלחה!')
        })
    }

    const forgot_password = () => {

    }

    const open_register = () => {
        slide_pop_info('בשלב זה ניתן להירשם באמצעות טופס יצירת קשר.');
        router.push('start');
    }
    
    onMounted(() => {
        if(window.innerWidth < 760){
            state.value = 'closed';
        }
    })

    watch(current_path.value, () => {
        let path = current_path.value.value.fullPath;
        if(path != '/'){
            change_bg();
        }else{
            change_back();
        }
    })

    const change_bg = () => {
        setTimeout(() => {
            const ele = document.getElementById('user-widget');
            if(ele){
                ele.style.background = 'var(--bg-bg)';
            }
        }, 250);
    }
    
    const change_back = () =>{
        const ele = document.getElementById('user-widget');
        if(ele){
            ele.style.background = 'linear-gradient(#00545dc4, #000729c2)';
        }
    }

    return{
        user, login, input_data, log_out,
        minimize_widget, state, show_widget,
        open_register, forgot_password, open_storage
    }
}
}
</script>

<style scoped>
.user-widget{
    position: absolute;
    left: 15px;
    top: 15px;
    width: 320px;
    height: 200px;
    border-radius: 10px;
    background: white;
    /* background: rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); */
    background: linear-gradient(#00545dc4, #000729a6);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    z-index: 10000;
}
.minimize{
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
}
.minimize img{
    max-width: 100%;
    max-height: 100%;
}
.no-user{
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    width: 100%;
    height: 15%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 26px;
    text-shadow: 1px 1px 3px #0000008a;
}

.login-btn{
    padding: 10px;
    width: 100%;
    height: 30%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}
.input_glass{
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    width: 70%;
    height: 100%;
    text-decoration: none;
    text-align: center;
    color: white;
    outline: none;
    font-size: 18px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
    border-bottom: 1px solid white;
    font-size: 18px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    
}

button{
    color: #fbfcfd;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    width: 70%;
    font-size: 2vh;
    height: 100%;
}
button:hover{
    background: linear-gradient(#00545db0, #000729ab);
}
.forgot-pass{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(29, 158, 115, 0.85);
}
.forgot-pass p{
    cursor: pointer;
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.scale-out-center {
	-webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
.user-icon{
    display: flex;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 7px;
    top: 15px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
}
.user-icon img{
    max-width: 100%;
    max-height: 100%;
}

/********************************************user-in**********************************************/
.user-in{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 50% 50%;
}
.user-btn{
    width: 100%;
    height: 100%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.user-btn-image{
    max-height: 70%;
    max-width: 70%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.user-btn-image:hover{
    transform: scale(1.1);
}
.user-btn-txt{
    width: 100%;
    text-align: center;
    color: rgba(245, 245, 245, 0.89);
}
.logout-red{
    color: rgb(231, 176, 176);
}
/*************************************************************************************************/
@media only screen and (max-width: 765px){
    .user-widget{
        display: none;
        top: 15px;
        width: 300px;
    }
}
</style>