import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About_us.vue')
  },
  {
    path: '/method',
    name: 'method',
    component: () => import('../views/Method.vue')
  },
  {
    path: '/start',
    name: 'start',
    component: () => import('../components/Start/Contact.vue')
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import('../views/Tools.vue')
  },
  {
    path: '/apps/prices',
    name: 'prices',
    component: () => import('../Apps/Prices/Prices.vue')
  },
  {
    path: '/apps/catalog',
    name: 'catalog',
    component: () => import('../Apps/Catalog/MainAdmin.vue')
  },
  {
    path: '/apps/catalog/preview',
    name: 'catalogprev',
    component: () => import('../Apps/Catalog/CatalogPreviewFull.vue')
  },
  // {
  //   path: '/reg',
  //   name: 'reg',
  //   component: () => import('../components/Auth/Register.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
