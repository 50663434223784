<template>
  <div class="main">
    <div class="update-alert" v-if="show_update_alert">
      <p>קיים עדכון חדש למערכת, אנא לחץ על עדכן מערכת!</p>
      <el-button type="success" style="margin-top:15px; width:300px" class="wobble-hor-bottom" @click="update_system">עדכן מערכת</el-button>
    </div>
    <SideBar/>
    <ChatBotBtn/>
    <UserWidget/>
    <UserStorage/>
    <router-view/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import SideBar from './components/Navbar/SideBar.vue';
import { computed, onMounted } from '@vue/runtime-core';
import store from './store';
import ChatBotBtn from './components/ChatBot/ChatBotBtn.vue';
import UserStorage from './components/User/Storage/MainStorage.vue';
import UserWidget from './components/User/UserWidget.vue';

export default {
  components:{ChatBotBtn, SideBar, UserStorage, UserWidget},
  setup() {
    let sw_detailes = null;
    const show_update_alert = ref(false);
    let refreshing = false;

    const user = ref(computed(() => {
      return store.getters.user
    }))

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      if(document.activeElement.type && document.activeElement.type === 'text'){
        console.log("Test input, no resize.")
      }
      else{
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    });

    const update_system = () => {
      show_update_alert.value = false;
      if(sw_detailes  || sw_detailes.waiting){
        sw_detailes.waiting.postMessage({type:'SKIP_WAITING'});
      }
    }

	  navigator.serviceWorker.addEventListener( 'controllerchange', () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });

    onMounted(() => {
      document.addEventListener( 'serviceWorkerUpdateEvent', (e) => {
        sw_detailes = e.detail;
        show_update_alert.value = true;
        }, { once: true }
      );
    })

    return{
      user, show_update_alert, update_system
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@200;300;400;500&display=swap');

:root{
  --bg-blue: #4285F4;
  --bg-red: #DB4437;
  --bg-yellow: #F4B400;
  --bg-green: #0F9D58;
  --bg-black: rgb(56, 56, 56);

  --bg-white: #fbfcfd;
  --bg-aqua: #00545db0;
  --bg-darkblue: #000729ab;
  --bg-blue: #426696;
  --bg-bg: linear-gradient(#00545d, #000729);
  --bg-bg-trans: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1));
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  direction: rtl;
  font-weight: 200;
  box-sizing: border-box;
}
#app {
  direction: rtl;
  font-family: 'Rubik', sans-serif;
  background: var(--bg-bg);
}
.main{
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}
::-webkit-scrollbar{
  width: 12px;
}
::-webkit-scrollbar-track{
  border: 7px solid var(--bg-overlay);
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(45deg, #06dee1, #79ff6c);
  border-radius: 3px;
}
.update-alert{
  position: absolute;
  background-color: rgb(2, 1, 17);
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100px;
  display: flex;
  color: whitesmoke;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  }
}

</style>
