import { createStore } from 'vuex'
import { projectFirestore } from '../firebase/config';
import router from '../router';

export default createStore({
  state: {
    user: null,
    show_user_widget: true,
    show_user_storage: false
  },
  mutations: {
  },
  actions: {
    set_user({ commit }, user){
      if(user){
        this.state.user = user;
        this.dispatch('getProfile')
      }else{
        this.state.user = null;
        router.replace('');
      }
    },
    async getProfile(){
      let uid = this.state.user.uid;
      let email = this.state.user.email;
      let user_data = await projectFirestore.collection('Users').doc(uid).get()
      if(user_data.exists){
        this.state.user.profile = user_data.data();
      }else{
        projectFirestore.collection('Users').doc(uid).set({
          name: '',
          last_name: '',
          uid: uid,
          phone: '',
          created: new Date(),
          role: 'client',
          permissions: [],
          email: email,
          notes: [],
          icon: 'none',
          apps:[]
        })
      }
    },
    toggle_user_widget(){
      this.state.show_user_widget = !this.state.show_user_widget
    },
    toggle_user_storage(){
      this.state.show_user_storage = !this.state.show_user_storage
    }

  },
  getters:{
    user(state){
      return state.user
    },
    user_widget(state){
      return state.show_user_widget
    },
    user_storage(state){
      return state.show_user_storage
    },
  }
})
